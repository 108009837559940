import React, { useEffect, useState } from "react";
import Axios from "axios";
import XboxSection from "./platforms/XboxSection";
import PlayStationSection from "./platforms/PlayStationSection";
import MobileSection from "./platforms/MobileSection";
import GameSection from "./platforms/GameSection";

const Sidebar = () => {
  const [platforms, setPlatforms] = useState([]);

  // Fetch platform data from API
  useEffect(() => {
    const fetchPlatforms = async () => {
      try {
        const response = await Axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/platforms`);
        setPlatforms(response.data.data); // Store platform data in state
        console.log("platforms data is: ", response?.data?.data);
      } catch (error) {
        console.error("Error fetching platforms:", error);
      }
    };
    fetchPlatforms();
  }, []);

  return (
    <div
      className="bg-[#00357C] text-white p-4 w-full md:w-[220px] lg:w-[17vw] xl:w-[17vw] h-full"
      style={{
        borderRight: "1px solid white",
        borderTop: "none",
        borderBottom: "none",
        borderLeft: "none",
      }}
    >
      <div className="text-center text-xl font-semibold mb-6">Platform</div>

      {platforms?.map((item, index) =>
        item?.attributes?.name?.toLowerCase() !== "playstation" ? (
          <GameSection
            key={item.id} // Added key for unique identification in list
            gameId={item?.id}
            platformName={item?.attributes?.name}
          />
        ) : (
          <PlayStationSection key={item.id} data={item} />
        )
      )}
    </div>
  );
};

export default Sidebar;
