import React, { useState } from "react";
import { FaShoppingCart } from "react-icons/fa"; // Cart icon
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom"; // For navigation
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { removeFromCart, clearCart } from "../../redux/cartSlice";

const CartSidebar = () => {
  const cart = useSelector((state) => state.cart); // Access cart from Redux store
  const token = useSelector((state) => state.auth.token);
  console.log("cart", cart);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [expandedPlatforms, setExpandedPlatforms] = useState({});
  const [expandedGames, setExpandedGames] = useState({});

  const togglePlatform = (platformName) => {
    setExpandedPlatforms((prev) => ({
      ...prev,
      [platformName]: !prev[platformName],
    }));
  };

  const toggleGame = (platformName, gameName) => {
    setExpandedGames((prev) => ({
      ...prev,
      [`${platformName}-${gameName}`]: !prev[`${platformName}-${gameName}`],
    }));
  };

  const calculateTotal = () => {
    let total = 0;

    const platformTotals = cart.games_list.reduce((acc, game) => {
      if (!acc[game.platform_name]) {
        acc[game.platform_name] = [];
      }
      acc[game.platform_name].push(game);
      return acc;
    }, {});

    Object.entries(platformTotals).forEach(([platformName, games]) => {
      if (platformName === "Playstation") {
        const playStationGamesCount = games.length;

        // if (playStationGamesCount <= 10) {
        //   total += 100; // Fixed price for up to 10 games
        // } else {
        //   total += 100 + (playStationGamesCount - 10) * 10; // $10 per extra game after 10
        // }
        total += playStationGamesCount * 10;
      } else {
        total += games.reduce((platformTotal, game) => {
          return (
            platformTotal +
            game.service_list.reduce(
              (serviceTotal, service) => serviceTotal + service.service_price,
              0
            )
          );
        }, 0);
      }
    });

    return total;
  };

  const handleCheckout = async () => {
    if (!token) {
      toast.error("You need to login before placing an order.", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    if (!cart || cart.games_list.length <= 0) {
      toast.error("You cannot checkout an empty cart.", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    const playStationGamesCount = cart.games_list.filter(
      (game) => game.platform_name === "Playstation"
    ).length;

    if (playStationGamesCount > 0 && playStationGamesCount < 10) {
      toast.error(
        "Due to the method used for Playstation services, a 10 game, $100 minimum is mandatory.",
        {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/orders/submit-cart`,
        cart,
        { withCredentials: true }
      );
      toast.success("Checkout successful!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      dispatch(clearCart());
      console.log("Checkout response:", response.data);
      navigate("/orders");
    } catch (error) {
      toast.error("Checkout failed! Please try again.", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      console.error("Error during checkout:", error);
    }
  };

  const renderServices = (platformName, gameName, serviceList) => {
    return serviceList.map((service, index) => (
      <li
        key={index}
        className="flex justify-between items-center mb-2 text-white"
      >
        <span className="text-sm">{service.service_name}</span>
        <div className="flex items-center space-x-2">
          <span className="text-sm">${service.service_price.toFixed(2)}</span>
          <button
            onClick={() => {
              dispatch(
                removeFromCart({
                  gameName,
                  platformName,
                  serviceName: service.service_name,
                })
              );
            }}
            className="flex items-center justify-center bg-red-600 text-white w-3 h-3 rounded-sm hover:bg-red-700 transition duration-300"
            aria-label="Remove Service"
          >
            <span className="text-sm font-bold">−</span>
          </button>
        </div>
      </li>
    ));
  };

  const renderGames = (platformName, games) => {
    return games.map((game) => (
      <div key={game.game_name} className="mb-2 text-red-600">
        <div className="flex justify-between items-center">
          <h5
            className="text-md font-semibold mb-1 cursor-pointer"
            onClick={() => toggleGame(platformName, game.game_name)}
          >
            {game.game_name}
          </h5>
          <button
            className="text-red-500 hover:text-red-700 transition duration-300"
            onClick={() => toggleGame(platformName, game.game_name)}
          >
            {expandedGames[`${platformName}-${game.game_name}`] ? "▲" : "▼"}
          </button>
        </div>
        {expandedGames[`${platformName}-${game.game_name}`] && (
          <ul className="ml-4">
            {renderServices(platformName, game.game_name, game.service_list)}
          </ul>
        )}
      </div>
    ));
  };

  const renderPlatforms = () => {
    const platforms = cart.games_list.reduce((acc, game) => {
      const platformName = game.platform_name;
      if (!acc[platformName]) {
        acc[platformName] = [];
      }
      acc[platformName].push(game);
      return acc;
    }, {});

    return Object.entries(platforms).map(([platformName, games]) => (
      <div key={platformName} className="mb-6">
        <div className="flex justify-between items-center">
          <h4
            className="text-lg font-semibold cursor-pointer"
            onClick={() => togglePlatform(platformName)}
          >
            <span
              style={{
                color: "white", // Base text color
                textShadow: `
                1px 1px 0 red, /* Right and Down */
                -1px 1px 0 red, /* Left and Down */
                1px -1px 0 red, /* Right and Up */
                -1px -1px 0 red /* Left and Up */
              `,
                transition: "all 0.3s ease",
              }}
            >
              {platformName}
            </span>
            {platformName == "Playstation" && games.length <= 10 ? (
              <span className="text-sm text-gray-200 ml-1">
                ({games.length}/10 games)
              </span>
            ) : (
              <span className="text-sm text-gray-200 ml-1">
                ({games.length} games)
              </span>
            )}
          </h4>
          <button
            className="text-red-700 hover:text-red-900 transition duration-300"
            onClick={() => togglePlatform(platformName)}
          >
            {expandedPlatforms[platformName] ? "▲" : "▼"}
          </button>
        </div>
        {expandedPlatforms[platformName] && (
          <div className="ml-4 max-h-[20vh] overflow-y-auto">
            {renderGames(platformName, games)}
          </div>
        )}
      </div>
    ));
  };

  return (
    <div
      className=" bg-[#00357C] text-white p-4 h-full md:w-[220px] lg:w-[17vw] xl:w-[17vw] sm:w-1"
      style={{
        borderRight: "none",
        borderTop: "none",
        borderBottom: "none",
        borderLeft: "1px solid white",
      }}
    >
      <div className="flex items-center justify-between mb-4">
        <h3 className="text-center text-xl font-semibold">Cart</h3>
        <FaShoppingCart className="text-white text-2xl" />
      </div>
      {renderPlatforms()}
      <div className="flex justify-between items-center mt-4 border-t pt-4">
        <span className="text-lg font-semibold">Total:</span>
        <span className="text-xl font-bold">
          ${calculateTotal().toFixed(2)}
        </span>
      </div>
      <button
        className="w-full mt-4 bg-blue-600 text-white py-2 rounded hover:bg-blue-700 flex items-center justify-center space-x-2"
        onClick={handleCheckout}
      >
        <FaShoppingCart className="text-white text-2xl" />
        <span>Proceed to Checkout</span>
      </button>
      <button
        className="w-full mt-2 bg-gray-600 text-white py-2 rounded hover:bg-gray-700"
        onClick={() => {
          token
            ? navigate("/orders")
            : toast.error("You need to login to see your orders", {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
        }}
      >
        View Order History
      </button>
    </div>
  );
};

export default CartSidebar;
