import React, { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import { toast } from "react-toastify";
import { FaChevronDown, FaChevronUp , FaSpinner} from "react-icons/fa"; // Import icons

const Orders = () => {
  const [orders, setOrders] = useState([]);
  const [expandedOrderId, setExpandedOrderId] = useState(null); // To track the expanded order
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState({});
  const [transactionId, setTransactionId] = useState({});
  const [loadingOrderId, setLoadingOrderId] = useState(null); // Track the order being processed
  const [loadingMessage, setLoadingMessage] = useState(""); // Loading message 
  const [isVerifying, setIsVerifying] = useState(false);
  // Fetch orders from API
  useEffect(() => {
    const fetchApi = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/api/orders/order-history/`,
          {
            headers: {
              "Content-Type": "application/json",
            },
            withCredentials: true,
          }
        );
        setOrders(response?.data || []);
        // toast.success("Orders loaded successfully!", {
        //   position: "top-right",
        // });
      } catch (err) {
        const message =
          err?.response?.data?.error?.message || "Failed to load orders.";
        toast.error(message, {
          position: "top-right",
        });
      }
    };
    fetchApi();
  }, []);

  // Toggle Expand/Collapse
  const toggleExpand = (orderId) => {
    setExpandedOrderId((prev) => (prev === orderId ? null : orderId));
    setSelectedPaymentMethod((prev) => {
      if (!prev.hasOwnProperty(orderId)) {
        return {
          ...prev,
          [orderId]: "Cash App",
        };
      }
      return prev;
    });    
  };

  const handlePaymentVerification = async (orderId) => {
    const paymentMethod = selectedPaymentMethod[orderId];
    const transaction = transactionId[orderId];

    // Set loading message and start the wait
    setIsVerifying(true);
    setLoadingOrderId(orderId);
    setLoadingMessage("Please wait, your verification is in progress. This usually takes upto 2 minutes....");

    // Wait for 60 seconds before making the API call
    setTimeout(async () => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/api/orders/verify`,
          {
            orderId,
            paymentMethod,
            transactionId: transaction,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
            withCredentials: true,
          }
        );

        // Show success toast if payment verification is successful
        toast.success("Payment verified successfully!", {
          position: "top-right",
        });
      } catch (error) {
        // Show error toast if API call fails
        toast.error("Failed to verify payment. Please try again.", {
          position: "top-right",
        });
      } finally {
        // Reset loading state after 60 seconds and API call completion
        setLoadingOrderId(null);
        setLoadingMessage("");
        window.location.reload();
      }
    }, 20000); // 60 seconds wait time
  };
  

  return (
    <div className="min-h-screen py-10 px-4">
      <h1 className="text-2xl font-bold text-center mb-8 text-white">Your Orders</h1>
      {orders.length > 0 ? (
        <div className="space-y-6">
          {orders.map((order) => (
            <div
              key={order.id}
              className="bg-white shadow-lg rounded-lg p-4 border"
            >
              {/* Order Header */}
              <div className="flex justify-between items-center">
                <div>
                  <h2 className="text-lg font-semibold">
                    {order.cart[0]?.platform?.name || "Unknown Platform"} -{" "}
                    {moment(order.createdAt).format("DD MMM, YYYY")}
                  </h2>
                  <span
                    className={`${
                      order.status.toLowerCase() === "unpaid"
                        ? "text-red-600"
                        : "text-green-600"
                    } font-bold`}
                  >
                    Status: {order.status}
                  </span>
                </div>
                <button
                  className="text-blue-600"
                  onClick={() => toggleExpand(order.id)}
                >
                  {expandedOrderId === order.id ? (
                    <FaChevronUp size={18} /> // Collapse icon
                  ) : (
                    <FaChevronDown size={18} /> // Expand icon
                  )}
                </button>
              </div>

              {/* Order Details (Expandable Section) */}
              {expandedOrderId === order.id && (
                <div className="mt-4">
                  {/* Order Table */}
                  <div className="overflow-x-auto">
                  <table className="w-full border-collapse border border-gray-300">
                    <thead>
                      <tr className="bg-gray-100">
                        <th className="border border-gray-300 px-4 py-2">
                          Game
                        </th>
                        <th className="border border-gray-300 px-4 py-2">
                          Service
                        </th>
                        <th className="border border-gray-300 px-4 py-2">
                          Price
                        </th>
                        <th className="border border-gray-300 px-4 py-2">
                          Quantity
                        </th>
                        <th className="border border-gray-300 px-4 py-2">
                          Total
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {order.cart.map((item) => (
                        <tr key={item.id}>
                          {console.log(item)}
                          <td className="border border-gray-300 px-4 py-2">
                            {item.game?.Game_Name || item?.playstation_game?.game_name}
                          </td>
                          <td className="border border-gray-300 px-4 py-2">
                            {item.service}
                          </td>
                          <td className="border border-gray-300 px-4 py-2">
                            ${item?.single_item_price?.toFixed(2) || 0}
                          </td>
                          <td className="border border-gray-300 px-4 py-2">
                            {item.quantity}
                          </td>
                          <td className="border border-gray-300 px-4 py-2">
                            ${item?.total_item_price?.toFixed(2) || 0}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  </div>

                  {/* Payment Details (For Unpaid Orders) */}
                  <div className="mt-4">
                    <p className="font-semibold">
                      Total:{" "}
                      <span className="text-blue-600 font-bold">
                        ${order.totalAmount.toFixed(2)}
                      </span>
                    </p>
                    {order.status.toLowerCase() === "unpaid" && (
                      <>
                        <div className="mt-4">
                          <label className="block mb-2 font-medium">
                            Select Payment Method:
                          </label>
                          <select
                            value={
                              selectedPaymentMethod[order.id] || "Cash App"
                            }
                            onChange={(e) =>
                              setSelectedPaymentMethod((prev) => ({
                                ...prev,
                                [order.id]: e.target.value,
                              }))
                            }
                            className="w-full border border-gray-300 rounded-lg px-4 py-2"
                          >
                            <option value="Cash App">Cash App</option>
                            <option value="PayPal">PayPal</option>
                            <option value="Venmo" disabled>
                              Venmo
                            </option>
                            <option value="Skrill" disabled>
                              Skrill
                            </option>
                            <option
                              value="Credit/Debit (Through PayPal)"
                              disabled
                            >
                              Credit/Debit (Through PayPal)
                            </option>
                          </select>
                        </div>
                        <div className="mt-4">
                          <label className="block mb-2 font-medium">
                            Transaction ID:
                          </label>
                          <input
                            type="text"
                            value={transactionId[order.id] || ""}
                            onChange={(e) =>
                              setTransactionId((prev) => ({
                                ...prev,
                                [order.id]: e.target.value,
                              }))
                            }
                            className="w-full border border-gray-300 rounded-lg px-4 py-2"
                          />
                        </div>
                        <div className="flex items-center space-x-4">
                          <button
                          className="bg-blue-600 text-white font-bold py-2 px-4 rounded-lg mt-4"
                          onClick={() => {
                            handlePaymentVerification(order.id);
                          }}
                        >
                          {/* Show spinner while verifying */}
                          {isVerifying && loadingOrderId === order.id ? (
                            <FaSpinner className="animate-spin" size={20} />
                          ) : (
                            "Verify Payment"
                          )}
                        </button>

                        {selectedPaymentMethod[order.id] === "PayPal" && (
                          <div className="text-red-600 mb-2">
                            <p>Please pay through PayPal at Id: <b>@TheFixerDude</b></p>
                          </div>
                        )}

                        {selectedPaymentMethod[order.id] === "Cash App" && (
                          <div className="text-red-600 mb-2">
                            <p>Please pay through Cash App at Id: <b>$TheFixerDude</b></p>
                          </div>
                        )}
                        </div>
                        {/* Display loading message */}
                        {isVerifying && loadingOrderId === order.id && (
                          <div className="mt-2 text-center text-gray-500">
                            {loadingMessage}
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      ) : (
        <p className="text-center text-gray-600">No orders found.</p>
      )}
    </div>
  );
};

export default Orders;
