import React, { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";

const EditProfile = () => {
  const navigate = useNavigate();
  const [fullName, setFullName] = useState("");
  const [profilePicture, setProfilePicture] = useState(null);
  const [profilePreview, setProfilePreview] = useState(""); // For previewing the image
  const [contactType, setContactType] = useState("Xbox Gamertag");
  const [contactField, setContactField] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const token = Cookies.get("authToken");
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/api/users/me`,
          {
            // headers: {
            //   Authorization: `Bearer ${token}`,
            // },
            withCredentials:true
          }
        );
        const userData = response.data;

        setFullName(userData.full_name || "");
        setContactType(userData.contact_type || "Xbox Gamertag");
        setContactField(userData.contact_field || "");

        if (userData.profile_picture) {
          const imageUrl = `${process.env.REACT_APP_BACKEND_URL}${userData.profile_picture}`;
          setProfilePreview(imageUrl);
        }
      } catch (error) {
        console.error("Error fetching profile data:", error);
        toast.error("Unable to fetch profile data.", {
          position: "top-right",
          autoClose: 2000,
        });
      }
    };

    fetchProfile();
  }, []);

  // Function to handle profile picture change
  const handleProfilePictureChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setProfilePicture(file);
      setProfilePreview(URL.createObjectURL(file));
    }
  };

  // Function to handle API call for profile update
  const updateProfile = async (formData) => {
    try {
      const token = Cookies.get("authToken");
      const response = await axios.put(
        `${process.env.REACT_APP_BACKEND_URL}/api/users/edit-profile`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          withCredentials: true,
        }
      );
      toast.success("Profile updated successfully!", {
        position: "top-right",
        autoClose: 2000,
      });
      return response.data;
    } catch (err) {
      const message =
        err?.response?.data?.error?.message || "Error updating profile.";
      toast.error(message, {
        position: "top-right",
        autoClose: 2000,
      });
      throw message;
    }
  };

  // Form submission handler
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!fullName || !contactField) {
      setError("All fields are required.");
      return;
    }

    setError("");

    // Prepare form data for submission
    const formData = new FormData();
    formData.append("full_name", fullName);
    if (profilePicture) formData.append("profile_picture", profilePicture);
    formData.append("contact_type", contactType);
    formData.append("contact_field", contactField);

    try {
      const result = await updateProfile(formData);
      navigate("/store");
      console.log("Profile updated:", result);
    } catch (err) {
      console.error("Update error:", err);
    }
  };

  return (
    <div className="flex justify-center items-center min-h-screen bg-[#00357C]">
      <div className="w-full max-w-lg bg-white p-8 rounded-lg shadow-md">
        <h2 className="text-2xl font-bold text-center text-gray-800 mb-6">
          Edit Profile
        </h2>

        {error && <div className="text-red-500 text-sm mb-4">{error}</div>}

        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-600">
              Username
            </label>
            <input
              type="text"
              value={fullName}
              onChange={(e) => setFullName(e.target.value)}
              className="w-full px-4 py-2 mt-2 border border-gray-300 rounded-lg"
              required
            />
          </div>

          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-600">
              Profile Picture
            </label>
            <input
              type="file"
              accept="image/*"
              onChange={handleProfilePictureChange}
              className="w-full px-4 py-2 mt-2 border border-gray-300 rounded-lg"
            />
            {profilePreview && (
              <div className="mt-4">
                <img
                  src={profilePreview}
                  alt="Profile Preview"
                  className="w-32 h-32 object-cover rounded-full mx-auto"
                />
              </div>
            )}
          </div>

          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-600">
              Choose the Preferred Contact Method 
            </label>
            <select
              value={contactType}
              onChange={(e) => setContactType(e.target.value)}
              className="w-full px-4 py-2 mt-2 border border-gray-300 rounded-lg"
            >
              <option value="Xbox Gamertag">Xbox Gamertag</option>
              <option value="Email">Email</option>
              <option value="Phone Number">Phone Number</option>
              <option value="Discord Name">Discord Name</option>
              <option value="Other">Other</option>
            </select>
          </div>

          <div className="mb-6">
            <label className="block text-sm font-medium text-gray-600">
              Enter Preferred Contact Details
            </label>
            <input
              type="text"
              value={contactField}
              placeholder={contactType == "Other" && "Please Enter as Followed: Platform: Username"}
              onChange={(e) => setContactField(e.target.value)}
              className="w-full px-4 py-2 mt-2 border border-gray-300 rounded-lg"
              required
            />
          </div>

          <button
            type="submit"
            className="w-full py-2 bg-blue-600 text-white font-semibold rounded-lg hover:bg-blue-700 transition duration-200"
          >
            Save Changes
          </button>
        </form>
      </div>
    </div>
  );
};

export default EditProfile;
