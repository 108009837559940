import React, { useEffect, useState } from "react";
import axios from "axios";
import { useDispatch } from "react-redux";
import { addToCart } from "../../redux/cartSlice";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

const GameDetailsPage = () => {
  const [gameData, setGameData] = useState(null);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/api/playstation-games/${id}?populate=*`
        );
        setGameData(response.data.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching game data:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, [id]);

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-t-4 border-red-600 border-solid border-transparent"></div>
      </div>
    );
  }

  
  if (!gameData) {
    return (
      <div className="text-xl text-red-500 text-center mt-12">
        Error loading game data
      </div>
    );
  }

  const { game_name, platform, service_list } = gameData.attributes;

  const handleAddToCart = () => {
    dispatch(
      addToCart({
        gameName: game_name,
        platformName: platform.data.attributes.name,
        service: {
          service_name: "All Service",
          service_type: "game",
          quantity: 1,
          service_price: 0,
        },
      })
    );
    toast.success(`${game_name} added to the cart successfully`, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  return (
    <div className="max-w-4xl mx-auto px-4 py-8">
      {/* Title */}
      <h1 className="text-3xl md:text-4xl font-bold text-white mb-6">
        {game_name}
      </h1>

      {/* Pricing Note */}
      <p className="text-red-600 font-bold italic mb-6">
        You need to buy a minimum of 10 games for $100 in the PlayStation
        section. Each game after $100 will be $10.
      </p>
      
      {/* Heading */}
      <h2 className="text-2xl text-white mb-4">Services Provided</h2>

      {/* Services */}
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6 mb-6">
        {service_list.map((service) => (
          <div
            key={service.id}
            className="p-4 border-2 border-blue-500 rounded-lg bg-blue-50 shadow-md hover:shadow-lg transition-transform transform hover:scale-105"
          >
            <p className="text-lg font-medium text-gray-800">
              {service.service_name}
            </p>
          </div>
        ))}
      </div>


      {/* Add to Cart Button */}
      <button
        className="bg-blue-500 text-white font-bold py-3 px-6 rounded shadow-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-300 transition-all"
        onClick={handleAddToCart}
      >
        Add {game_name} to Cart
      </button>
    </div>
  );
};

export default GameDetailsPage;
