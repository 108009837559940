import { createSlice } from "@reduxjs/toolkit";

// Get initial state from localStorage or use an empty cart
const initialState = JSON.parse(localStorage.getItem("cart")) || {
  games_list: [],
};

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    addToCart: (state, action) => {
      const { gameName, platformName, service } = action.payload;

      const existingGame = state.games_list.find(
        (game) =>
          game.game_name === gameName && game.platform_name === platformName
      );

      if (existingGame) {
        const existingService = existingGame.service_list.find(
          (item) => item.service_name === service.service_name
        );

        if (existingService) {
          Object.assign(existingService, service);
        } else {
          existingGame.service_list.push(service);
        }
      } else {
        state.games_list.push({
          game_name: gameName,
          platform_name: platformName,
          service_list: [service],
        });
      }

      // Save to localStorage
      localStorage.setItem("cart", JSON.stringify(state));
    },
    removeFromCart: (state, action) => {
      const { gameName, platformName, serviceName } = action.payload;

      const gameIndex = state.games_list.findIndex(
        (game) =>
          game.game_name === gameName && game.platform_name === platformName
      );

      if (gameIndex !== -1) {
        const serviceIndex = state.games_list[gameIndex].service_list.findIndex(
          (service) => service.service_name === serviceName
        );

        if (serviceIndex !== -1) {
          state.games_list[gameIndex].service_list.splice(serviceIndex, 1);

          if (state.games_list[gameIndex].service_list.length === 0) {
            state.games_list.splice(gameIndex, 1);
          }
        }
      }
      // Save to localStorage
      localStorage.setItem("cart", JSON.stringify(state));
    },
    clearCart: (state) => {
      state.games_list = [];
      localStorage.removeItem("cart");
    },
  },
});

export const { addToCart, removeFromCart, clearCart } = cartSlice.actions;
export default cartSlice.reducer;
