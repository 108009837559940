import React, { useState } from "react";
import axios from "axios";
import logo from "../../assets/LogoWhite_cropped.png";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";

const Signup = () => {
  // State to store form data
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const navigate = useNavigate();

  // Form submission handler
  const registerUser = async (email, password) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/users/register`,
        { email, password },
        // {
        //   headers: {
        //     "Content-Type": "application/json",
        //   },
        // }
      );
      toast.success("User registered successfully!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return response.data;
    } catch (err) {
      toast.error(err?.response?.data?.error?.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      // throw err.response?.data?.message || "Something went wrong.";
    }
  };

  // Form submission handler
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Form validation
    if (!email || !password) {
      setError("Both email and password are required.");
      return;
    }

    // Simple email validation regex
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!emailRegex.test(email)) {
      setError("Please enter a valid email address.");
      return;
    }

    // Clear previous messages
    setError("");

    try {
      // Call the API
      const result = await registerUser(email, password);
      if(result.error){
        const errorMessage =
          error?.response?.data?.message || "Registration failed. Please try again.";
        setError(errorMessage);
    
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 2000,
        });
      return;
      }
      toast.success("User created Successfully", {
        position: "top-right",
        autoClose: 2000,
      });
  
      // Navigate to login only if the API call is successful
      console.log("Registration success:", result);
      navigate("/login");
    } catch (error) {
      // Set error message and show error toast
      const errorMessage =
        error?.response?.data?.message || "Registration failed. Please try again.";
      setError(errorMessage);
  
      // toast.error(errorMessage, {
      //   position: "top-right",
      //   autoClose: 3000,
      // });
  
      console.error("Registration error:", error);
    }
  };
  

  return (
    <div className="flex justify-center items-center min-h-screen">
      <div className="w-full max-w-md bg-white p-8 rounded-lg shadow-lg border-2 border-pink-300">
        <div className="flex justify-center mb-6">
          <img
            src={logo}
            alt="The Fixer Dude Logo"
            className="h-20 w-20 object-contain"
          />
        </div>

        <h2 className="text-2xl font-bold text-center text-gray-800 mb-6">
          Sign Up
        </h2>

        {/* Display error message */}
        {error && <div className="text-red-500 text-sm mb-4">{error}</div>}

        {/* Signup Form */}
        <form onSubmit={handleSubmit}>
          {/* Email field */}
          <div className="mb-4">
            <label
              htmlFor="email"
              className="block text-sm font-medium text-gray-600"
            >
              Email
            </label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full px-4 py-2 mt-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Enter your email"
              required
            />
          </div>

          {/* Password field */}
          <div className="mb-6">
            <label
              htmlFor="password"
              className="block text-sm font-medium text-gray-600"
            >
              Password
            </label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="w-full px-4 py-2 mt-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Enter your password"
              required
            />
          </div>

          {/* Submit Button */}
          <button
            type="submit"
            className="w-full py-2 bg-blue-600 text-white font-semibold rounded-lg hover:bg-blue-700 transition duration-200"
          >
            Sign Up
          </button>
        </form>

        <div className="mt-4 text-center">
          <p className="text-sm text-gray-500">
            Already have an account?{" "}
            <a href="/login" className="text-blue-500 hover:underline">
              Login here
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Signup;
