import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import "./App.css";
import Navbar from "./components/shared/Navbar";
import Footer from "./components/shared/Footer";
import Sidebar from "./components/shared/Sidebar";
import CartSidebar from "./components/shared/CartSidebar";
import ServicesPage from "./components/shared/gamePage";
import Login from "./pages/auth/Login";
import Signup from "./pages/auth/Signup";
import EditProfile from "./pages/main/EditProfile";
import LandingPage from "./pages/auth/LandingPage";
import PlaystationGameDetailsPage from "./components/shared/playstationPage";
import Store from "./pages/main/Store";
import Orders from "./pages/main/Orders";
import { ToastContainer } from "react-toastify";
function App() {
  // Use useLocation inside the App component
  const location = useLocation();
  const hidePaths = ["/login", "/signup", "/edit-profile", "/"]; // Paths to hide sidebars
  const shouldHideSidebars = hidePaths.includes(location.pathname);

  return (
    <div className="flex flex-col min-h-screen">
      {/* Navbar */}
      <Navbar />
      <ToastContainer />
      <div className="flex flex-1 overflow-hidden">
        {/* Conditionally Render Sidebar and CartSidebar */}
        <div className="hidden md:block">
          {!shouldHideSidebars && <Sidebar />}
        </div>
        {/* Main Content Area */}
        <div className="flex-1 overflow-auto">
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route
              path="/playstation-games/:id"
              element={<PlaystationGameDetailsPage />}
            />
            <Route path="/edit-profile" element={<EditProfile />} />
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/store" element={<Store />} />
            <Route path="/orders" element={<Orders />} />
            <Route path="/services/:gameId" element={<ServicesPage />} />
            <Route path="/cart" element={<CartSidebar />} />
          </Routes>
        </div>

        {/* Conditionally Render CartSidebar */}
        <div className="hidden md:block">
          {!shouldHideSidebars && <CartSidebar />}
        </div>
      </div>

      {/* Footer */}
      <Footer />
    </div>
  );
}

function WrappedApp() {
  return (
    <Router>
      <App />
    </Router>
  );
}

export default WrappedApp;
