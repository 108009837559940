import React from "react";
import logo from "../../assets/LogoWhite_cropped.png";

const Footer = () => {
  return (
    <footer class="bg-[#001a3e] text-white py-6">
      <div class="text-center mb-4">
        <img src={logo} alt="TheFixerDude Logo" class="mx-auto h-16" />
      </div>

      <div class="text-center space-y-4">
        <div>
          <p class="text-2xl font-semibold text-white">TheFixerDude Services</p>
        </div>

        <div>
          <p class="text-sm font-light text-white">
            ©2022 by TheFixerDude Services
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
