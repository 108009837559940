// XboxSection.js
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const GameSection = ({ gameId, platformName, onClick = () => {} }) => {
  const [gameData, setGameData] = useState(null);
  const [pagination, setPagination] = useState(null);
  const [isExpanded, setIsExpanded] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const navigate = useNavigate();

  // Function to toggle the expansion of the section
  const toggleExpansion = () => setIsExpanded(!isExpanded);

  // Function to fetch game data from the backend
  const fetchGameData = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/games/platform/${gameId}`
      ); // Replace with your API endpoint
      if (!response.ok) {
        throw new Error("Failed to fetch game data");
      }
      const data = await response.json();
      console.log("data is: ", data);
      setGameData(data?.results);
      setPagination(data?.pagination);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  // Use effect to trigger the API call when the component mounts
  useEffect(() => {
    fetchGameData();
  }, []);

  if (loading) return (
    <div className="flex justify-center items-center min-h-screen">
      <div className="animate-spin rounded-full h-12 w-12 border-t-4 border-red-600 border-solid border-transparent"></div>
    </div>
  );
  if (error) return <div>Error: {error}</div>;

  return (
    <div>
      <div
        className="flex justify-between items-center cursor-pointer mb-4"
        onClick={toggleExpansion}
        aria-label={isExpanded ? "Collapse Xbox Filter" : "Expand Xbox Filter"}
      >
        <h3 className="text-lg font-semibold" style={{
              color: "white", // Base text color
              textShadow: `
                1px 1px 0 red, /* Right and Down */
                -1px 1px 0 red, /* Left and Down */
                1px -1px 0 red, /* Right and Up */
                -1px -1px 0 red /* Left and Up */
              `,
              transition: "all 0.3s ease",
            }}>
          {platformName}{" "}
          <span className="text-sm text-gray-400 ml-1">
            {/* ({data.length * 100}) */}
          </span>
        </h3>
        <span
          className={`transform transition-transform ${
            isExpanded ? "rotate-90" : ""
          }`}
        >
          <svg
            className="w-4 h-4"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
          >
            <path
              d="M6 9l6 6 6-6"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </span>
      </div>

      {/* Xbox List with max height of 15vh and scroll */}
      <div
        className={
          isExpanded ? "block max-h-[20vh] overflow-y-auto mb-4" : "hidden"
        }
      >
        <ul>
          {gameData?.map((item) => (
            <li key={item.id} className="flex justify-between mb-2">
              <a
                onClick={() =>{
                  navigate(`/services/${item.id}`, {
                    state: { gameName: item.Game_Name },
                  });
                  onClick();
                }}
                // href="#"
                className="text-sm text-white hover:text-red-500 cursor-pointer font-semibold"
              >
                {item?.Game_Name}
              </a>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default GameSection;
