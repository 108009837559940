import React, { useEffect, useState } from "react";
import List from "rc-virtual-list"; // Import rc-virtual-list
import { useNavigate } from "react-router-dom";

const PlayStationSection = ({ gameId, onClick = () => {} }) => {
  const [gameData, setGameData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(20); // Number of items per page
  const [totalPages, setTotalPages] = useState(1);
  const [isExpanded, setIsExpanded] = useState(false);

  const navigate = useNavigate();

  // Function to toggle the expansion of the section
  const toggleExpansion = () => setIsExpanded(!isExpanded);

  // Function to fetch game data from the backend
  const fetchGameData = async (page) => {
    try {
      setLoading(true);
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/playstation-games?pagination[page]=${page}&pagination[pageSize]=${pageSize}`
      );
      if (!response.ok) {
        throw new Error("Failed to fetch game data");
      }
      const data = await response.json();
      console.log("data is: ", data);
      setGameData((prevData) => [...prevData, ...data.data]);
      setTotalPages(data.meta.pagination.pageCount); // Set the total number of pages
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  // Use effect to trigger the API call when the component mounts
  useEffect(() => {
    fetchGameData(currentPage);
  }, [currentPage]);

  // Handle scrolling to load more data
  const handleScroll = (e) => {
    const bottom =
      e.target.scrollHeight === e.target.scrollTop + e.target.clientHeight;
    if (bottom && currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1); // Load next page if bottom is reached
    }
  };

  if (loading && currentPage === 1) return (
    <div className="flex justify-center items-center min-h-screen">
      <div className="animate-spin rounded-full h-12 w-12 border-t-4 border-red-600 border-solid border-transparent"></div>
    </div>
  );
  if (error) return <div>Error: {error}</div>;

  return (
    <div>
      <div
        className="flex justify-between items-center cursor-pointer mb-4"
        onClick={toggleExpansion}
        aria-label={
          isExpanded
            ? "Collapse PlayStation Filter"
            : "Expand PlayStation Filter"
        }
      >
        <h3 className="text-lg font-semibold">
          <span style={{
              color: "white", // Base text color
              textShadow: `
                1px 1px 0 red, /* Right and Down */
                -1px 1px 0 red, /* Left and Down */
                1px -1px 0 red, /* Right and Up */
                -1px -1px 0 red /* Left and Up */
              `,
              transition: "all 0.3s ease",
            }}>PlayStation{" "}</span>
          {/* <span className="text-sm text-gray-400 ml-1">
            ({gameData.length})
          </span> */}
        </h3>
        <span
          className={`transform transition-transform ${
            isExpanded ? "rotate-90" : ""
          }`}
        >
          <svg
            className="w-4 h-4"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
          >
            <path
              d="M6 9l6 6 6-6"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </span>
      </div>

      {/* PlayStation List with max height of 20vh and scroll */}
      <div
        className={
          isExpanded ? "block max-h-[20vh] overflow-y-auto mb-4" : "hidden"
        }
        onScroll={handleScroll} // Attach scroll handler to detect when to load more
      >
        <List
          data={gameData}
          height={200} // Height of the virtualized list
          itemHeight={50} // Height of each list item
          itemKey="id" // Unique key for each list item
          onScroll={handleScroll}
        >
          {(item) => (
            <li key={item.id} className="flex justify-between mb-2">
              <a
                onClick={() => {
                  navigate(`/playstation-games/${item.id}`, {
                    state: { gameName: item?.attributes?.game_name },
                  })
                  onClick()
                }}
                className="text-sm text-white hover:text-red-500 cursor-pointer font-semibold"
              >
                {item?.attributes?.game_name}
              </a>
            </li>
          )}
        </List>
      </div>
    </div>
  );
};

export default PlayStationSection;
