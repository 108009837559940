import React from "react";
import logo from "../../assets/store.png";

const Store = () => {
  return (
    <div className="flex flex-col items-center bg-[#00357C] min-h-screen py-8 px-4">
      {/* Banner Section */}
      <div className="w-full max-w-5xl">
        <img src={logo} alt="Store Banner" className="w-full" />
      </div>

      {/* Title Section */}
      <div className="text-center mt-8 max-w-4xl">
        <h2 className="text-lg md:text-xl lg:text-2xl font-bold text-pink-300 underline">
          ~~~BEWARE OF IMPOSTERS~~~
        </h2>
        <p className="text-sm md:text-base lg:text-lg text-gray-200 mt-4 font-semibold">
          IF YOU ARE BEING ASKED TO PAY ANYWHERE ELSE, THAN WHAT'S BELOW, YOU
          ARE BEING SCAMMED.
        </p>
        <div className="mt-4">
          <p className="text-base md:text-lg lg:text-xl font-medium text-white">
            <strong>PayPal:</strong>{" "}
            <span className="text-blue-300">@TheFixerDude</span>
          </p>
          <p className="text-base md:text-lg lg:text-xl font-medium text-white">
            <strong>CashApp:</strong>{" "}
            <span className="text-green-300">$TheFixerDude</span>
          </p>
          <p className="text-base md:text-lg lg:text-xl font-medium text-white">
            <strong>Venmo:</strong>{" "}
            <span className="text-red-300">@TheFixerDude (Ph# 1008)</span>
          </p>
          <p className="text-base md:text-lg lg:text-xl font-medium text-white">
            <strong>Skill:</strong>{" "}
            <span className="text-yellow-300">thefixerdudepayments@gmail.com</span>
          </p>
          <p className="text-base md:text-lg lg:text-xl font-medium text-white">
            <strong>Credit/Debit:</strong>{" "}
            <span className="text-purple-300">By Request at owner@thefixerdude.com</span>
          </p>
        </div>
        <div className="max-w-4xl mx-auto p-6 space-y-6 text-left">
          <div className="text-center">
            <h2 className="text-2xl font-bold text-gray-200">
              Some services require access to your account, whether it is your main account or an alternate account.
            </h2>
            <p className="mt-2 text-2xl font-bold text-gray-200">This is industry standard.</p>
          </div>

          <div className="text-center">
            <h3 className="text-xl font-bold mt-4 text-gray-200">
              What is not standard is the level of service we offer to make sure it's a comfortable situation for you
            </h3>
          </div>

          <div className="mt-6">
            <ul className="space-y-4">
              <li className="text-lg">
                <span className="font-semibold text-gray-200">• We suggest you enable two-factor authentication, use the authentication app, or use a one-time code sent to your email to verify the login at the time of service. We can assist in setting up any of these services, please ask.</span>
                <span className="block text-sm italic mt-1 text-gray-200">(This ensures to all parties involved that your password is protected and stays private)</span>
              </li>
              <li className="text-lg">
                <span className="font-semibold text-gray-200">• At your request, we will livestream the service and you can watch and be present with us at the time of service.</span>
                <span className="block text-sm italic mt-1 text-gray-200">(This gives you the most transparent available service, only second to doing it yourself)</span>
              </li>
              <li className="text-lg">
                <span className="font-semibold text-gray-200">• At your request, you can choose not to be present and have the service done at a preferable time when you will be away.</span>
                <span className="block text-sm italic mt-1 text-gray-200">(You will need to disable 2FA and provide the information we need ahead of time. This is a great option for those with busy schedules)</span>
              </li>
              <li className="text-lg">
                <span className="font-semibold text-gray-200">• While we provide all the information we have available and are willing to give our opinion on the level of risk that comes with any service we do and put every effort into minimizing any risk to your account, we cannot be responsible, liable, or offer refunds if there are unforeseen adverse results.</span>
              </li>
              <li className="text-lg">
                <span className="font-semibold text-gray-200">• Any updates to a game that reverse or change the results of your service cannot be redone free of charge, but a discount may be given at our discretion.</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Store;
