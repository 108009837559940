import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FaBars, FaShoppingCart, FaTimes } from "react-icons/fa"; // For hamburger and close icons
import logo from "../../assets/LogoWhite_cropped.png";
import Axios from "axios";
import Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import { clearToken } from "../../redux/authSlice";
import GameSection from "./platforms/GameSection";
import PlayStationSection from "./platforms/PlayStationSection";
import { FaSignInAlt, FaSignOutAlt } from 'react-icons/fa';

const Navbar = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [userData, setUserData] = useState(null); // Store user data
  const [platforms, setPlatforms] = useState([]); // Store platform data
  const token = useSelector((state) => state.auth.token);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Fetch platform data from API
  useEffect(() => {
    const fetchPlatforms = async () => {
      try {
        const response = await Axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/platforms`);
        setPlatforms(response.data.data);
      } catch (error) {
        console.error("Error fetching platforms:", error);
      }
    };
    fetchPlatforms();
  }, []);

  const closeMenu = () => {
    setMenuOpen(false);
  };

  const openMenu = () => {
    setMenuOpen(true);
  };

  // Fetch user data
  useEffect(() => {
    const fetchUserData = async () => {
      if (token) {
        try {
          const response = await Axios.get(
            `${process.env.REACT_APP_BACKEND_URL}/api/users/me`,
            {
              withCredentials: true,
            }
          );
          setUserData(response.data);
        } catch (error) {
          console.error("Error fetching user data:", error);
        }
      }
    };
    fetchUserData();
  }, [token]);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const renderAvatar = () => {
    if (userData?.profile_picture) {
      const imageUrl = `${process.env.REACT_APP_BACKEND_URL}${userData.profile_picture}`;
      return (
        <div className="relative group">
        <img
          src={imageUrl}
          alt="User Avatar"
          className="w-10 h-10 rounded-full object-cover cursor-pointer"
          onClick={() => navigate("/edit-profile")}
        />
        <span className="absolute left-1/2 -translate-x-1/2 bottom-[-35px] bg-gray-700 text-white text-sm px-2 py-1 rounded opacity-0 group-hover:opacity-100 transition-opacity duration-300">
          Edit Profile
        </span>
        </div>
      );
    }

    const firstLetter = userData?.full_name?.charAt(0).toUpperCase() || "U";
    return (
      <div className="relative group">
        <div
          className="w-10 h-10 bg-red-700 text-white rounded-full flex items-center justify-center text-lg font-bold cursor-pointer"
          onClick={() => {navigate("/edit-profile");closeMenu();}}
        >
          {firstLetter}
        </div>
        <span className="absolute left-1/2 -translate-x-1/2 bottom-[-35px] bg-gray-700 text-white text-sm px-2 py-1 rounded opacity-0 group-hover:opacity-100 transition-opacity duration-300">
          Edit Profile
        </span>
      </div>
    );
  };

  const handleLogout = async () => {
    try {
      const response = await Axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/users/logout`,
        {},
        { withCredentials: true }
      );

      if (response.status === 200) {
        dispatch(clearToken());
        navigate("/login");
      } else {
        console.error("Logout failed:", response.data.message);
      }
    } catch (error) {
      console.error("Logout error:", error);
    }
  };

  return (
    <nav className="bg-[#00357C] p-4 shadow-lg sticky top-0 z-50">
      <div className="container mx-auto flex items-center justify-between">
        {/* Hamburger Icon */}
        <div className="md:hidden flex items-center">
          <button
            onClick={toggleMenu}
            className="text-white text-2xl focus:outline-none"
            aria-label="Toggle Menu"
          >
            {menuOpen ? <FaTimes /> : <FaBars />}
          </button>
        </div>

        {/* Logo */}
        <div className="flex items-center justify-center md:justify-start flex-1">
          <a href="/">
            <img
              src={logo}
              alt="The Fixer Dude Logo"
              className="h-10 w-auto md:h-12 lg:h-14 object-contain"
            />
          </a>
          <div className="lg:hidden md:hidden flex items-center space-x-4 ml-auto">
            <Link to="/store" 
            className="text-lg font-bold text-2xl border-t-2 border-b-2 border-red-700 px-4 py-2 transition-all duration-300 hover:text-red-700 hover:border-white hover:underline"
            onClick={openMenu}
            style={{
              color: "white",
              textShadow: `
                1px 1px 0 red, 
                -1px 1px 0 red, 
                1px -1px 0 red, 
                -1px -1px 0 red
              `,
              transition: "all 0.3s ease",
            }}
            onMouseEnter={(e) => {
              e.target.style.color = "red";
              e.target.style.textShadow = `
                1px 1px 0 white, 
                -1px 1px 0 white, 
                1px -1px 0 white, 
                -1px -1px 0 white
              `;
            }}
            onMouseLeave={(e) => {
              e.target.style.color = "white";
              e.target.style.textShadow = `
                1px 1px 0 red, 
                -1px 1px 0 red, 
                1px -1px 0 red, 
                -1px -1px 0 red
              `;
            }}>Store</Link>
            <Link to="/cart" className="text-white hover:text-red-500 text-lg" onClick={closeMenu}>
              <FaShoppingCart size={20} />
            </Link>
            {!token ? (
              <Link to="/login" onClick={closeMenu} className="w-10 h-10 bg-red-700 rounded-full flex items-center justify-center hover:bg-white hover:text-red-700 transition duration-300">
                <FaSignInAlt size={20} className="text-white" />
              </Link>
            ) : (
              <>
                {renderAvatar()}
                <button
                  onClick={handleLogout}
                  className="w-10 h-10 bg-red-700 rounded-full flex items-center justify-center hover:bg-white hover:text-red-700 transition duration-300"
                >
                  <FaSignOutAlt size={20} className="text-white" />
                </button>
              </>
            )}
          </div>
        </div>

        <div className="hidden md:flex space-x-8 flex-1 justify-center">
          <Link
            to="/store"
            className="font-bold text-2xl border-t-2 border-b-2 border-red-700 px-4 py-2 transition-all duration-300 hover:text-red-700 hover:border-white hover:underline"
            style={{
              color: "white",
              textShadow: `
                1px 1px 0 red, 
                -1px 1px 0 red, 
                1px -1px 0 red, 
                -1px -1px 0 red
              `,
              transition: "all 0.3s ease",
            }}
            onMouseEnter={(e) => {
              e.target.style.color = "red";
              e.target.style.textShadow = `
                1px 1px 0 white, 
                -1px 1px 0 white, 
                1px -1px 0 white, 
                -1px -1px 0 white
              `;
            }}
            onMouseLeave={(e) => {
              e.target.style.color = "white";
              e.target.style.textShadow = `
                1px 1px 0 red, 
                -1px 1px 0 red, 
                1px -1px 0 red, 
                -1px -1px 0 red
              `;
            }}
          >
            Store
          </Link>
        </div>
        
        {/* Avatar or Login */}
        <div className="hidden md:flex flex-1 justify-end items-center space-x-4">
          {token ? renderAvatar() : (
            <Link
              to="/login"
              className="text-white bg-red-700 px-4 py-2 rounded-lg hover:bg-white hover:text-red-700 hover:font-bold transition duration-300"
            >
              Login
            </Link>
          )}
          {token && (
            <button
              onClick={handleLogout}
              className="text-white bg-red-700 px-4 py-2 rounded-lg hover:bg-white hover:text-red-700 hover:font-bold transition duration-300"
            >
              Logout
            </button>
          )}
        </div>
      </div>

      {/* Mobile Sidebar UI */}
      {menuOpen && (
        <>
          <div className="mt-4 bg-blue-700 rounded-lg p-4 shadow-lg md:hidden h-[100vh] ">
            <div className="text-center text-xl font-semibold mb-6 text-white">
              Platform
            </div>
            {platforms.map((item) =>
              item.attributes.name.toLowerCase() !== "playstation" ? (
                <GameSection
                  key={item.id}
                  gameId={item.id}
                  platformName={item.attributes.name}
                  onClick={toggleMenu}
                />
              ) : (
                <PlayStationSection
                  key={item.id}
                  data={item}
                  onClick={toggleMenu}
                />
              )
            )}
          </div>
        </>
      )}
    </nav>
  );
};

export default Navbar;
