import React from "react";
import logo from "../../assets/LogoWhite_cropped.png";
import vid from "../../assets/videos/video.mp4";
import { FaFacebook, FaInstagram } from "react-icons/fa";

const LandingPage = () => {
  return (
    <div className="flex flex-col items-center justify-center w-full px-4 bg-[#00357C] text-white min-h-screen">
      {/* Logo Section */}
      <div className="flex flex-col items-center mt-10 space-y-4">
        <img
          src={logo}
          alt="The Fixer Dude Logo"
          className="w-28 h-28 md:w-36 md:h-36 lg:w-44 lg:h-44 rounded-full object-cover shadow-xl hover:scale-105 transition-transform duration-300 ease-in-out"
        />
        <h1 className="text-3xl md:text-4xl lg:text-5xl font-extrabold text-center tracking-wide">
          The Fixer Dude
        </h1>
      </div>

      {/* Video Section */}
      <div className="relative w-full max-w-4xl aspect-video bg-gray-800 rounded-xl shadow-2xl overflow-hidden mt-10 hover:scale-105 transition-transform duration-300 ease-in-out mb-40">
        <video
          className="w-full h-full object-cover"
          src={vid}
          autoPlay
          loop
          muted
          controls
        />
      </div>
    </div>
  );
};

export default LandingPage;
