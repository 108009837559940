import React, { useRef, useEffect } from "react";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import "../../styles/gallery.css"; // Custom CSS for the gallery

const Gallery = ({ image_list }) => {
  console.log("images", image_list);
  return (
    <div className="gallery-container">
      <ImageGallery
        items={image_list}
        showFullscreenButton={false}
        showPlayButton={false}
      />
    </div>
  );
};

export default Gallery;
