import React, { useEffect, useState } from "react";
import "../../styles/services.css";
import axios from "axios";
import PropTypes from "prop-types";
import Gallery from "./gallery";
import { useSelector, useDispatch } from "react-redux";
import { addToCart, removeFromCart } from "../../redux/cartSlice";
import { useParams } from "react-router-dom";
const ServicesPage = () => {
  const { gameId } = useParams();
  const dispatch = useDispatch();
  const [gameData, setGameData] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedOptions, setSelectedOptions] = useState({});
  const [selectedPackage, setSelectedPackage] = useState({});
  const [numberInputValues, setNumberInputValues] = useState({});
  const [serviceName, setServiceName] = useState("");
  const [errors, setErrors] = useState({});

  useEffect(() => {
    const fetchGameData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/api/games/${gameId}`
        );
        setGameData(response.data.game);
        setSelectedCategory(response.data.game.current_category);
      } catch (error) {
        console.error("Error fetching game data:", error);
      }
    };
    fetchGameData();
  }, [gameId]);

  useEffect(() => {
    const fetchGameData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/api/games/${gameId}/?serviceCategory=${serviceName}`
        );
        setGameData(response.data.game);
        setSelectedCategory(response.data.game.current_category);
      } catch (error) {
        console.error("Error fetching game data:", error);
      }
    };
    serviceName?.length > 0 && fetchGameData();
  }, [serviceName]);

  const handleNumberInputChange = (
    serviceName,
    value,
    lowerLimit,
    upperLimit,
    unitPrice
  ) => {
    setNumberInputValues((prev) => ({
      ...prev,
      [serviceName]: value,
    }));

    if (value < lowerLimit || value > upperLimit) {
      setErrors((prev) => ({
        ...prev,
        [serviceName]: `Please enter a value between ${lowerLimit} and ${upperLimit}`,
      }));
      dispatch(
        removeFromCart({
          gameName: gameData.Game_Name,
          platformName: gameData.Assign_To_Platform.name,
          serviceName: serviceName,
        })
      );
    } else {
      setErrors((prev) => {
        const updatedErrors = { ...prev };
        delete updatedErrors[serviceName];
        return updatedErrors;
      });
      dispatch(
        addToCart({
          gameName: gameData.Game_Name,
          platformName: gameData.Assign_To_Platform.name,
          service: {
            service_name: serviceName,
            service_type: "numberInput",
            userInput: value,
            quantity: 1,
            service_price: value * unitPrice,
          },
        })
      );
    }
  };

  const handleCategoryClick = (category) => {
    setServiceName(category);
    setSelectedCategory(category);
  };

  const handleOptionChange = (counterServiceName, optionName, isChecked) => {
    setSelectedOptions((prev) => {
      const updatedOptions = { ...prev };
      if (!updatedOptions[counterServiceName]) {
        updatedOptions[counterServiceName] = new Set();
      }

      if (isChecked) {
        updatedOptions[counterServiceName].add(optionName);
      } else {
        updatedOptions[counterServiceName].delete(optionName);
      }

      // Convert the updated Set to an array after updating the state
      const selectedOptionsArray = Array.from(
        updatedOptions[counterServiceName]
      );

      // Check if a package is already selected for this counter service
      if (selectedPackage[counterServiceName]) {
        const calculatedPrice =
          selectedOptionsArray.length *
          selectedPackage[counterServiceName].package_price;
        dispatch(
          addToCart({
            gameName: gameData.Game_Name,
            platformName: gameData.Assign_To_Platform.name,
            service: {
              service_name: counterServiceName,
              service_type: "counterServices",
              options: selectedOptionsArray,
              package_name: selectedPackage[counterServiceName].package_name,
              quantity: 1,
              service_price: calculatedPrice,
            },
          })
        );
      } else if (selectedOptionsArray.length === 0) {
        // Remove from cart if there are no options selected
        dispatch(
          removeFromCart({
            gameName: gameData.Game_Name,
            platformName: gameData.Assign_To_Platform.name,
            serviceName: counterServiceName,
          })
        );
      }

      return updatedOptions; // Return the updated options to set the state
    });
  };

  const handlePackageChange = (
    counterServiceName,
    packageName,
    packagePrice
  ) => {
    setSelectedPackage((prev) => ({
      ...prev,
      [counterServiceName]: {
        package_name: packageName,
        package_price: packagePrice,
      },
    }));

    const selectedOptionsArray = Array.from(
      selectedOptions[counterServiceName] || []
    );
    if (selectedOptionsArray.length > 0) {
      const calculatedPrice = selectedOptionsArray.length * packagePrice;
      dispatch(
        addToCart({
          gameName: gameData.Game_Name,
          platformName: gameData.Assign_To_Platform.name,
          service: {
            service_name: counterServiceName,
            service_type: "counterServices",
            options: selectedOptionsArray,
            package_name: packageName,
            quantity: 1,
            service_price: calculatedPrice,
          },
        })
      );
    }
  };

  const handleCheckboxChange = (service) => {
    console.log("service", service);
    if (service.checked) {
      dispatch(
        addToCart({
          gameName: gameData.Game_Name,
          platformName: gameData.Assign_To_Platform.name,
          service: {
            service_name: service.service_name,
            service_type: "Checkboxes",
            quantity: 1,
            service_price: service.service_price,
          },
        })
      );
    } else {
      dispatch(
        removeFromCart({
          gameName: gameData.Game_Name,
          platformName: gameData.Assign_To_Platform.name,
          serviceName: service.service_name,
        })
      );
    }
  };

  const handleDropdownChange = (serviceName, selectedOption) => {
    dispatch(
      removeFromCart({
        gameName: gameData.Game_Name,
        platformName: gameData.Assign_To_Platform.name,
        serviceName: serviceName,
      })
    );

    if (selectedOption) {
      dispatch(
        addToCart({
          gameName: gameData.Game_Name,
          platformName: gameData.Assign_To_Platform.name,
          service: {
            service_name: serviceName,
            service_type: "Dropdown",
            userInput: selectedOption.service_name,
            service_price: selectedOption.service_price,
            quantity: 1,
          },
        })
      );
    }
  };

  const handleTextInputChange = (serviceName, text) => {
    if (text) {
      dispatch(
        addToCart({
          gameName: gameData.Game_Name,
          platformName: gameData.Assign_To_Platform.name,
          service: {
            service_name: serviceName,
            service_type: "textInput",
            userInput: text,
            quantity: 1,
            service_price: 0,
          },
        })
      );
    } else {
      dispatch(
        removeFromCart({
          gameName: gameData.Game_Name,
          platformName: gameData.Assign_To_Platform.name,
          serviceName: serviceName,
        })
      );
    }
  };

  const galleryImages = gameData?.image_list?.map((img) => ({
    original: `${process.env.REACT_APP_BACKEND_URL}${img?.image_url}`,
    thumbnail:
      `${process.env.REACT_APP_BACKEND_URL}${img?.formats?.thumbnail?.url}` ||
      `${process.env.REACT_APP_BACKEND_URL}${img?.image_url}`,
  }));

  return (
    <div className="services-container">
      <div className="game-info">
        <h1 className="game-title">{gameData?.Game_Name}</h1>
        <p className="game-description">{gameData?.Game_Description}</p>
      </div>
      <div className="gallery-container">
        {galleryImages && <Gallery image_list={galleryImages} />}
      </div>

      <div className="button-group">
        {gameData?.category_list.map((category) => (
          <button
            key={category}
            onClick={() => handleCategoryClick(category)}
            className={selectedCategory === category ? "active" : ""}
          >
            {category}
          </button>
        ))}
      </div>

      {selectedCategory && (
        <div className="service-content flex flex-col gap-5 mt-8 text-left sm:flex-row sm:gap-8 sm:max-w-xl">
          {gameData?.Checkboxes.map((checkboxCategory) => (
            <div key={checkboxCategory.id} className="form-item">
              <h3>{checkboxCategory.category_name}</h3>
              <div className="checkbox-list">
                {checkboxCategory.list.map((item, index) => (
                  <div
                    key={item.id}
                    className={`checkbox-item ${
                      index % 2 === 0 ? "left" : "right"
                    }`}
                  >
                    <input
                      type="checkbox"
                      id={`checkbox-${item.id}`}
                      onChange={(e) =>
                        handleCheckboxChange({
                          ...item,
                          checked: e.target.checked,
                        })
                      }
                    />
                    <label htmlFor={`checkbox-${item.id}`}>
                      <span className="checkbox-title">
                        {item.service_name} - ${item.service_price.toFixed(2)}
                      </span>
                      <span className="checkbox-description">
                        {item.service_note}
                      </span>
                    </label>
                  </div>
                ))}
              </div>
              <p className="category-description">
                {checkboxCategory.description_or_note}
              </p>
            </div>
          ))}

          {gameData?.Dropdown.map((dropdownCategory) => (
            <div key={dropdownCategory.id} className="form-item">
              <h3>{dropdownCategory.title}</h3>
              <select
                className="dropdown-select"
                onChange={(e) => {
                  const selectedOption =
                    e.target.value !== "" ? JSON.parse(e.target.value) : null;
                  handleDropdownChange(dropdownCategory.title, selectedOption);
                }}
              >
                <option value="">Select an option</option>
                {dropdownCategory.list.map((item) => (
                  <option
                    key={item.id}
                    value={JSON.stringify({
                      service_name: item.service_name,
                      service_price: item.service_price,
                    })}
                  >
                    {item.service_name} - ${item.service_price.toFixed(2)}
                  </option>
                ))}
              </select>
              {dropdownCategory.description && (
                <p className="dropdown-description">
                  {dropdownCategory.description}
                </p>
              )}
            </div>
          ))}

          {gameData?.counterServices.map((counterService) => (
            <div key={counterService.id} className="counter-service form-item">
              <h3>{counterService.title}</h3>
              <div className="counter-options">
                <h4>Choose Avengers</h4>
                <div className="options-list">
                  {counterService.counterOptions.map((option) => (
                    <label key={option.id} className="option-checkbox">
                      <input
                        type="checkbox"
                        onChange={(e) =>
                          handleOptionChange(
                            counterService.title,
                            option.option_name,
                            e.target.checked
                          )
                        }
                      />
                      {option.option_name}
                    </label>
                  ))}
                </div>
              </div>

              <div className="packages">
                <h4>Packages</h4>
                {counterService.counter_services_packages.map((pkg) => (
                  <label key={pkg.id} className="package-radio">
                    <input
                      type="radio"
                      name={`package-${counterService.id}`}
                      onChange={() =>
                        handlePackageChange(
                          counterService.title,
                          pkg.package_name,
                          pkg.package_price
                        )
                      }
                    />
                    {pkg.package_name} - ${pkg.package_price.toFixed(2)}
                  </label>
                ))}
              </div>
            </div>
          ))}

          <div className="form-item-row">
            {gameData?.numberInput.map((numInput) => (
              <div key={numInput.id} className="form-item-half">
                <h3>{numInput.title}</h3>
                <input
                  type="number"
                  min={numInput.lower_limit}
                  max={numInput.upper_limit}
                  placeholder={`Enter value between ${numInput.lower_limit} and ${numInput.upper_limit}`}
                  className={`number-input ${
                    errors[numInput.title] ? "error" : ""
                  }`}
                  value={numberInputValues[numInput.title] || ""}
                  onChange={(e) =>
                    handleNumberInputChange(
                      numInput.title,
                      parseInt(e.target.value),
                      numInput.lower_limit,
                      numInput.upper_limit,
                      numInput.unit_price
                    )
                  }
                />
                {numInput.description && (
                  <p className="input-description">{numInput.description}</p>
                )}
                {errors[numInput.title] && (
                  <p className="error-text">{errors[numInput.title]}</p>
                )}
              </div>
            ))}

            {gameData?.textInput.map((textInput) => (
              <div key={textInput.id} className="form-item-half">
                <h3>{textInput.title}</h3>
                <input
                  type="text"
                  placeholder="Enter text here"
                  className="text-input"
                  onChange={(e) =>
                    handleTextInputChange(textInput.title, e.target.value)
                  }
                />
                {textInput.description && (
                  <p className="input-description">{textInput.description}</p>
                )}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

ServicesPage.propTypes = {
  gameId: PropTypes.number.isRequired,
};

export default ServicesPage;
